﻿/**
 *  @ngdoc controller
 *  @name Dx history Directive
 *  @description Dx history Directive
 */
(function () {
    "use:strict";

    angular.module("myApp").directive('dxhistoryModel', function () {
        return {
            controller: 'dxhistoryController',
            restrict: 'E',
            scope: {
                modelId: "@",
               // lensData: '=',
                customer: '=',
               // patientname: '=',
               // displayOnly: '='
            },
            templateUrl: 'app/directive/dxHistory/dxhistorydir.html'
        }
    }).controller('dxhistoryController', function ($scope, customerResource) {


        $scope.customer.getDxHistory = (cust) => {
            $scope.accountNo = cust.accountNo;
            $scope.practiceName = cust.practiceName;
            customerResource.getDxCustomerHistory({ id: cust.practiceId, }, function (res) {
               // console.log('directive data' + JSON.stringify(res));
                $scope.customerDxHistory = res;
            });
        }
        $scope.getLensDetails = (cust) => {
            $('#dxHistory').modal('hide');
            //$scope.accountNo = cust.accountNo;
            //$scope.practiceName = cust.practiceName;
            customerResource.getDxLensDetails({ orderId: cust.orderId, }, function (res) {
                // console.log('directive data' + JSON.stringify(res));
                $scope.dfsSetId = res.dfsSetId;
                $scope.dxLensDetails = res.orderDfs;
                $('#dxLensHistory').modal('show');
            });
        }

        $scope.closeModal = function () {
            $('#dxHistory').modal('show');
        }
     
    });

}(window.angular));